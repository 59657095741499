import React, { useEffect, useRef } from "react"
import './App.css';
import { Container } from "react-bootstrap";
import * as TREE from "three"
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
//import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
//import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
//import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
//import Objetostl from './assets/obj1/Objeto.stl'
//import Objetoobj from './assets/obj1/Objeto.obj'
//import Objetomtl from './assets/obj1/Objeto.mtl'
import Objetogltf from './assets/obj1/Objeto.gltf'
//import Tono from './assets/obj1/Objeto.mp3'

function App() {
  const Contenedor = useRef();

  useEffect(() => {
    var scene, camera, renderer, mesh1, trigger = 0;
    var keyboard = {};
    //var audioctrl;
    var player = {
      height: -5,
      speed: 10
    };
    scene = new TREE.Scene();
    camera = new TREE.PerspectiveCamera(3000, window.screen.width / window.screen.height, 0.1, 3000)

    /*  var mtlloader = new MTLLoader();
     mtlloader.load(Objetomtl, function(materials){
       materials.preload();
       var objloader = new OBJLoader();
       objloader.setMaterials(materials);
       objloader.load(Objetoobj, function(mesh){
         scene.add(mesh);
         mesh.position.set(0,-5,0);
         mesh.rotation.y=-Math.PI/2;
         mesh1 = mesh;
         trigger=1;
       },
       function ( xhr ) {
         console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded MTL' );
       })
     },
     function ( xhr ) {
       console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded OBJ' );
     }) */

    /* const material = new TREE.MeshPhysicalMaterial({
      color: 0xffd966,
      metalness: 0.25,
      roughness: 0.1,
      //opacity: 1.0,
      //transparent: false,
      transmission: 0.5,
      clearcoat: 1.0,
      clearcoatRoughness: 0.25
    })

    var objloader = new STLLoader();
    objloader.load(Objetostl, function (geometry) {
      const mesh = new TREE.Mesh(geometry, material)

      const exporter = new GLTFExporter();
      // Parse the input and generate the glTF output
      exporter.parse(
        mesh,
        // called when the gltf has been generated
        function (gltf) {
          console.log(gltf);
          
        }
      ); 
      scene.add(mesh);

      mesh.position.set(0, 0, 0);
      mesh.rotation.y = -Math.PI / 2;
      mesh1 = mesh;
      trigger = 1;
    },
      function (xhr) {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded STL');
      })*/


     const loader = new GLTFLoader();

    loader.load(Objetogltf, function (gltf) {
      mesh1 = gltf;
      scene.add(gltf.scene);
      trigger = 1;
    }, undefined, function (error) {

      console.error(error);

    }); 

   /*  new TREE.AudioLoader().load(
      Tono,
      function ( buffer ) {

        const listener = new TREE.AudioListener();
        const audio = new TREE.Audio( listener ).setBuffer( buffer );

        listener.position.z = 1;
        
        scene.add( audio );
        scene.add( listener );
        audio.loop=true;
        //audio.play();
        audioctrl = audio;
        
        
      }); */
    //scene.add(mesh);

    const ambientLight = new TREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);
    const light = new TREE.PointLight(0xffffff, 0.8, 0);
    light.position.set(0, 30, 0);
    light.castShadow = true;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 25
    scene.add(light)

    camera.position.set(0, player.height, -1000);
    camera.lookAt(new TREE.Vector3(0, player.height, 0))
    renderer = new TREE.WebGLRenderer({ alpha: true });
    
    
    renderer.setSize(window.screen.width, window.screen.height);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = TREE.BasicShadowMap;
    Contenedor.current.appendChild(renderer.domElement);
    animate();

    function animate() {
      requestAnimationFrame(animate);
      /* mesh.rotation.x += 0.01;
      mesh.rotation.y -= 0.02; */
      if (trigger === 1) {
        mesh1.scene.rotation.x -= 0.02;
        mesh1.scene.rotation.y -= 0.02;
      }


      if (keyboard[87]) {
        camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
        camera.position.z -= -Math.cos(camera.rotation.y) * player.speed;
      }

      if (keyboard[83]) {
        camera.position.x += Math.sin(camera.rotation.y) * player.speed;
        camera.position.z += -Math.cos(camera.rotation.y) * player.speed;
      }

      if (keyboard[68]) {
        camera.rotation.y += Math.PI * 0.01;
        //audioctrl.pause();
      }

      if (keyboard[65]) {
        camera.rotation.y -= Math.PI * 0.01;
        //audioctrl.pause();
      }

      renderer.render(scene, camera);
    }

    function keydown(event) {

      keyboard[event.keyCode] = true;
    }

    function keyup(event) {
      keyboard[event.keyCode] = false;
    }

    window.addEventListener('keyup', keyup)
    window.addEventListener('keydown', keydown)

  }, []);

  return (
    
      
      <Container style={{margin:'0px', padding:'0px'}} ref={Contenedor}>

   
      
    </Container>

  );
}


export default App;
